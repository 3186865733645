import React from "react";
import { graphql, Link } from "gatsby";
import { Helmet } from "react-helmet";

import { ArrowSmLeftIcon, ArrowSmRightIcon } from "@heroicons/react/outline";

import { Header } from "../sections/Header";
import Layout from "./Layout";

const PostPreview = (props) => (
  <div className="flex flex-col rounded-lg shadow-lg overflow-hidden bg-white">
    <div className="flex-shrink-0">
      {props.featuredImageSrc && (
        <img
          className="h-48 w-full object-cover"
          src={props.featuredImageSrc}
          alt="Preview of a post"
        />
      )}
    </div>
    <div className="p-6">
      <p className="text-xl font-semibold text-gray-900 text-center mb-4">
        {props.title}
      </p>

      {props.prev && (
        <div className="flex items-center justify-center">
          <ArrowSmLeftIcon className="w-5 h-5 mr-2 text-blue-500" />
          Poprzedni post
        </div>
      )}

      {props.next && (
        <div className="flex items-center justify-center">
          Następny post{" "}
          <ArrowSmRightIcon className="w-5 h-5 ml-2 text-blue-500" />
        </div>
      )}
    </div>
  </div>
);

export default function BlogPost({ data, pageContext }) {
  const { prev, next } = pageContext;
  const post = data.allWpPost.nodes[0];

  return (
    <Layout>
      <Helmet>
        <title>{post.title}</title>
        {post.featuredImage.node.sourceUrl && <meta property="og:image" content={post.featuredImage.node.sourceUrl} />}
      </Helmet>
      <Header />

      <div className="bg-white overflow-hidden">
        <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div className="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen" />
          <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
            <div>
              <h2 className="text-base text-blue-500 font-semibold tracking-wide uppercase">
                {post.categories.nodes[0].name}
              </h2>
              <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                {post.title}
              </h3>
            </div>
          </div>
          <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
            <div className="relative lg:row-start-1 lg:col-start-2">
              <svg
                className="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={384}
                  fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
                />
              </svg>
              {post.featuredImage && (
                <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
                  <figure>
                    <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                      <img
                        className="rounded-lg shadow-lg object-cover object-center"
                        src={post.featuredImage.node.sourceUrl}
                        alt="Featured cover"
                      />
                    </div>
                  </figure>
                </div>
              )}
            </div>
            <div className="mt-8 lg:mt-0">
              <div
                dangerouslySetInnerHTML={{ __html: post.content }}
                className="prose lg:prose-xl"
              />
            </div>
          </div>

          {/* Previous/Next post */}
          <div className="md:grid gap-5 grid-cols-3 hidden relative flex my-20 text-base text-blue-500 font-semibold tracking-wide uppercase">
            <div>
              {prev && (
                <Link to={`/blog/${prev.slug}`}>
                  <PostPreview
                    prev
                    featuredImageSrc={prev.featuredImage?.node?.sourceUrl}
                    slug={prev.slug}
                    title={prev.title}
                  />
                </Link>
              )}
            </div>
            <div></div>
            <div>
              {next && (
                <Link to={`/blog/${next.slug}`}>
                  <PostPreview
                    next
                    featuredImageSrc={next.featuredImage?.node?.sourceUrl}
                    slug={next.slug}
                    title={next.title}
                  />
                </Link>
              )}
            </div>
          </div>

          {/* Previous/Next post for mobile */}
          <div className="md:hidden block my-10 text-xl font-semibold text-blue-500 flex">
            {prev && (
              <Link to={`/blog/${prev.slug}`} className="flex items-center">
                <ArrowSmLeftIcon className="w-5 h-5 mr-2 text-blue-500" />
                Poprzedni post
              </Link>
            )}
            {next && (
              <Link
                to={`/blog/${next.slug}`}
                className="flex items-center ml-auto mr-0"
              >
                Następny post{" "}
                <ArrowSmRightIcon className="w-5 h-5 ml-2 text-blue-500" />
              </Link>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($slug: String!) {
    allWpPost(filter: { slug: { eq: $slug } }) {
      nodes {
        title
        content
        author {
          node {
            name
          }
        }
        featuredImage {
          node {
            sourceUrl
          }
        }
        categories {
          nodes {
            name
          }
        }
      }
    }
  }
`;
